.container-content {
  display: flex;
  justify-content: space-between;
}

.subscribe {
  height: 54px;
  /* background: url(../images/subscribe.png) no-repeat 0 0; */
  background: #463830;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  line-height: 48px;
  padding: 0px 20px;
  display: flex;
}

.subscribeIcon {
  padding: 7px;
}
.heading {
  background-color: #f6f6f7;
  padding: 0.5rem;
}

.heading h4 {
  color: #cf362d;
  font-size: x-large;
  /* white-space: nowrap; */
  margin: 0;
  /* text-align: center; */
  font-weight: normal;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
  /* padding: 5px; */
}

.heading p {
  float: right;
  color: #b67a70;
}

.header-container-left {
  flex: 0.6;
}
.header-container-left {
  flex: 0.4;
}

.highlight-card-root {
  display: flex;
  flex-direction: column;
}

.highlight-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0px 10px 0px;
  height: 16rem;
}

.highlight-card-holder {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.highlight-card-container {
  display: flex;
  flex-direction: column;
}

.highlight-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  flex-wrap: wrap;
}

.highlight-card-content h2 {
  color: #cf362d;
  font-size: medium;
  text-align: center;
  font-weight: normal;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
}

.highlight-card-content div {
  font-size: small;
}

.highlight-card-content a {
  font-size: small;
}

@media (min-width: 600px) {
  .highlight-card-content {
    flex-wrap: nowrap;
  }
  .highlight-card-content h2 {
    font-size: large;
  }
}

.highlight-card-content-data {
  width: 100%;
}

.highlight-card-content-data a {
  color: #425282;
}

.highlight-card-pagination {
  margin: 25px;
  display: flex;
  justify-content: center;
}

.highlight-card-pagination-list {
  background: #f7f7f9;
  border-radius: 20px;
  border: 3px solid #425282;
}
