.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-scroll {
}

.footer-scroll:hover {
  cursor: pointer;
  color: #fff;
}
