.home {
  width: 100%;
}

@media (min-width: 600px) {
  .home {
    margin-top: 6rem;
  }
}

.home h3 {
  font-size: 24px;
  font-weight: normal;
  color: #c73331;
  padding-bottom: 10px;
  padding-top: 10px;
  text-shadow: 0 1px 0 #fff;
}

.contact-container {
  display: flex;
  justify-content: center;
  padding: 6rem;
  align-items: center;
}

.contact-image {
  /* border: 0.2rem solid #000000; */
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
}

.contact-image-container {
  /* padding: 0.1rem;
  background: #b81505;
  border: 0.1rem solid #000000;
  border-radius: 2rem; */
}

.MuiChip-root {
  color: black;
  margin: 0.3rem;
  padding: 10px;
  font-size: large;
  font-weight: 350;
  background-color: #f6f6f7;
}

.MuiChip-root:hover {
  padding: 1rem;
  font-size: x-large;
}
