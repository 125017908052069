.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.header {
  background-image: url(../src/images/backgroundHeader1.gif);
  background-size: contain;
  background-position: top center;
}

@media (min-width: 600px) {
  .header {
    height: 35rem;
  }
}

.content {
  background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
}

@media (min-width: 600px) {
  .content {
    margin-top: 3rem;
    min-height: 40rem;
  }
}

.page-heading {
  border-radius: 20;
}

.footer {
  background-color: #b81505;
}

.container {
  /* width: 980px; */
  /* width: 70%; */
  margin: 0 auto;
}

@media (min-width: 600px) {
  .container {
    width: 70%;
  }
}
